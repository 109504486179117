
export const environment = {
  version: '1.21.5',
  production: true,
  old_url: 'https://cdtsys.it',
  api: {
    endpoint: 'https://cdtsys.it/api',
    client_id: '9577603a-1170-4f55-89a3-235f8c3cf555',
    client_secret: 'B40zqZRi369dqCutFBTjzcbaA2palHWbqAG33DRV',
  },
  google_maps_api_key: 'AIzaSyCSAAjIV0Y2X7Mg_PZLnYnSo5KYcFs3yuk',
  google_oauth2_client_id: '501459663065-safa7eh6aua7ulhu011iq73afeub2nio.apps.googleusercontent.com',
  rd_client_id: 'undefined',
  pusher_app_key: '5f4c82c13a6e9f267d30',
  pusher_cluster: 'mt1',
  logrocket: 'tlgo9t/cdtsys',
  max_upload_size: 314572800,
  languages: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'pt_BR': 'Brazilian Portuguese',
    'en': 'English',
    'es': 'Spanish'
  }
};
